import { ClientPanels, ClientTabs, FubSpotPanelTop } from "slot-definitions"
import { SecureTab, SecureTabPanel } from "minimals-template/components/secure-tabs"
import { Frag } from "lib/@components/slot/frag"
import { UpdateCompanyDetails } from "./update-company-details"
import { Lazy } from "lib/make-lazy"

ClientTabs.plug(
    <SecureTab
        demand={"admin-fubspot"}
        hide={true}
        fallback={<Frag />}
        label="FubSpot"
        value="FubSpot"
        data-cy="fubspot-tab"
    />
)
FubSpotPanelTop.plug(<UpdateCompanyDetails />)

ClientPanels.plug(
    <SecureTabPanel demand={"admin-fubspot"} hide={true} fallback={<Frag />} value="FubSpot">
        <Lazy
            importer={() => import("./fubspot-panel.ui.js")}
            extract="FubspotPanel"
            label={"FubspotPanel"}
            priority={50}
        />
    </SecureTabPanel>
)

import { ClientPanels, ClientTabs } from "slot-definitions"
import { SecureTab, SecureTabPanel } from "minimals-template/components/secure-tabs"

import { Lazy } from "lib/make-lazy"

ClientTabs.plug(<SecureTab demand="admin-debug" label="Debug" value="Debug" data-cy="debug-tab" />)

ClientPanels.plug(
    <SecureTabPanel value="Debug" demand="admin-debug">
        <Lazy
            importer={() => import("routes/sfg20-admin/plugins/companies-tab/tabs/debug/debug-licences.ui")}
            extract="LicenseInfo"
            label={"LicenseInfo"}
            priority={50}
        />
    </SecureTabPanel>
)

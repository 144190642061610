import { List, ListItem, ListItemText } from "@mui/material"

export function ProductAuditDetail({ content, id }) {
    const contentArray = content.split("|")
    return (
        <List dense data-cy="product-audit-${id}">
            {contentArray.map((item, index) => {
                const [field, action, from, to] = item.split(":")
                let itemText
                if (action === "modified") {
                    itemText = `${field} modified from ${from} to ${to}`
                } else {
                    itemText = `${field} ${action} ${from}`
                }
                return (
                    <ListItem key={`${field}-${index}`}>
                        <ListItemText primary={itemText} data-cy={`${id}-${index}-text`} />
                    </ListItem>
                )
            })}
        </List>
    )
}
